import styles from "./Partners.module.scss";
import Title from "../ui/Title/Title";
import iki from "../../assets/partners/iki.svg";
import bmwi from "../../assets/partners/bmwi.svg";
import bmwiMobile from "../../assets/partners/BMWI_mobile.jpg";
import giz from "../../assets/partners/GIZ.svg";
import aga from "../../assets/partners/aga.svg";
import CardLogo from "../ui/CardLogo/CardLogo";
import { AnimationOnScroll } from "react-animation-on-scroll";

const PartnersData = [
	{
		id: 3,
		src: giz,
	},
	{
		id: 4,
		src: aga,
	},

];


export default function Partners() {
	const lang = localStorage.getItem("lang");
	return (
		<AnimationOnScroll animateIn="animate__bounceIn">
			<div id="partners" className={styles.container}>
				<Title title={lang === "RU" ? "Наши " : "Our "} subtitle={lang === "RU" ? "партнёры" : "partners"}></Title>

				<div className={styles.Card}>
					<div className={styles.row}>
						<img alt="bmwi" src={bmwiMobile} className={styles.mobile}></img>
						<img alt="bmwi" className={styles.partnerLogo} src={bmwi}></img>
						<CardLogo alt="iki" src={iki} />
					</div>
					{PartnersData.map((partner, index) => (
						<CardLogo alt="partner" key={index} src={partner.src} />
					))}
					<img width={150} alt="risha" className={styles.risha} src={"/img/risha.png"}></img>
				</div>
			</div>
		</AnimationOnScroll>
	);
}