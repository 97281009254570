import { Link } from 'react-router-dom'
import Title from '../../../ui/Title'
import classes from './section.module.scss'
import { useQuery } from '@tanstack/react-query';
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher'
import { IProjectData } from '../../model/project'
import { Card } from '../Card/Card';


export const Section = () => {
	const lang = localStorage.getItem("lang");

    const { data, isError, isLoading } = useQuery<IProjectData>({
		queryKey: ['projects', lang],
		queryFn: () => {
			return getFetcher(`project/get`)
		}
	})


    if(isError && isLoading) {
        return null
    }

    return <div
            className={classes.section}
            id={'projects'}
        >
        <div className={classes.left} >
            <div className={classes.info} >
                <Title 
                    title={lang === 'RU' ? "СГБ" : 'SGBs'}
                    subtitle={lang === 'RU' ? 'Профили' : 'Profiles' }
                    // description={lang === 'RU' ? 'Критерии рассмотрения вашей заявки' : 'Criteria for consideration of your application'}
                />
            </div>
            {data?.data.data.project[0] && <Link 
                to={`/projects/${data?.data.data.project[0].id}`} 
                className={classes.button} 
                onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                >
                {lang === 'RU' ? 'Показать все' : 'Show more'}
            </Link>}
        </div>
        <div className={classes.right} >
            <div className={classes.items} >
                {data?.data.data.project.slice(0, 4).map((item) => <Card key={item.id} project={item} />)}
            </div>
        </div>
</div>
}